import Alpine from 'alpinejs';

// Components

// Global
window.Alpine = Alpine;

// Inits
Alpine.start();



const swiper = new Swiper('.swiper', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    // slidesPerView: 3.5,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    resizeObserver: true,
    breakpointsBase: "container",
    on: {
      slideChange: function () {
      },
  
      init: function () {
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 200);
      }
    },
    // Responsive breakpoints
    breakpoints: {
      500: {
        slidesPerView: 2
      },
      800: {
        slidesPerView: 3
      },
      1100: {
        slidesPerView: 4
      },
      1500: {
        slidesPerView: 5
      }
    },
  
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
    },
  
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  
    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar',
    },
  });